import React, { useState, useEffect } from 'react';
import { database } from "../firebaseConfig";
import { ref, get, query, orderByChild, equalTo } from 'firebase/database';
import './ArtistSongs.css';
import playIcon from '../img/play.svg';
import pauseIcon from '../img/pause.svg';
import AudioBar from '../Audio/AudioBar';
import { Link } from 'react-router-dom';
import SpotifySongs from '../Audio/SpotifySongs';


function ArtistSongs({ artistID }) {
  const [songs, setSongs] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const cardsToShow = 3;
  const [currentSong, setCurrentSong] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [showAudioBar, setShowAudioBar] = useState(false); // Neue State-Variable
  const [showSpotifyPlayer, setShowSpotifyPlayer] = useState(false);

  useEffect(() => {
    const fetchSongs = async () => {
      const songsRef = query(ref(database, 'Songs'), orderByChild('artistID'), equalTo(artistID));
      const snapshot = await get(songsRef);
      if (snapshot.exists()) {
        const songsData = snapshot.val();
        const songsArray = Object.entries(songsData).map(([id, data]) => ({
          id,
          ...data,
        }));
        setSongs(songsArray);
      }
    };

    fetchSongs();
  }, [artistID]);

  const togglePlayPause = (song) => {
    if (currentSong && song.id === currentSong.id) {
      // Wenn der aktuelle Song erneut geklickt wird, stoppen oder pausieren
      setIsPlaying(!isPlaying);
    } else {
      // Andernfalls setzen Sie den neuen Song und spielen ihn ab
      setCurrentSong(song);
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    // Wenn isPlaying auf false gesetzt wird, setzen Sie currentSong zurück
    if (!isPlaying) {
      setCurrentSong(null);
    }
  }, [isPlaying]);

  return (
    <div className="home-nominees-2" style={{minHeight: '50vh'}}>
      <h3>Songs</h3>
      <div className="home-nominees-grid" style={{ paddingBottom: '30px' }}>
        {songs.map(song => (
          <div key={song.id} className="home-nominee-song">
            <div className="home-nominee-song-top">
              <img src={song.songCoverUrl} alt={`${song.name} cover`} />
            </div>
            <div className="home-nominee-song-bottom">
              <Link to={`/song/${song.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <h4>{song.name}</h4>
              </Link>
              <p>{song.artist}</p>
            </div>
            <button className="play-pause-button" style={{ position: 'absolute', bottom: '85px', left: '35px' }} onClick={() => togglePlayPause(song)}>
              {isPlaying && currentSong && currentSong.id === song.id ? 
                <img src={pauseIcon} style={{ marginLeft: '1px' }} className="pause-icon" alt="Pause" /> : 
                <img src={playIcon} className="play-icon" alt="Play" />}
            </button>
          </div>
        ))}
      </div>
      {isPlaying && currentSong && (
        <div className="audio-bar-container">
          {currentSong.fileUrl ? (
            <AudioBar
              url={currentSong.fileUrl}
              songImg={currentSong.songCoverUrl || currentSong.imageUrl}
              artistName={currentSong.artist}
              songName={currentSong.name}
              isPlaying={isPlaying}
              togglePlayPause={() => setIsPlaying(false)} // Hier ändern Sie einfach den isPlaying Zustand, um die Wiedergabe zu pausieren
              className="audio-bar"
            />
          ) : (
            <SpotifySongs spotifyUrl={currentSong.spotifyLink} />
          )}
        </div>
      )}
    </div>
  );
  
}

export default ArtistSongs;
