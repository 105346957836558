import React from 'react';
import './ReviewPreviewStars.css';

const ReviewPreviewStars = ({ reviewScore, totalReviews }) => {
    let fullStars = 0;
    let hasHalfStar = false;
    let emptyStars = 5;

    if (reviewScore != null && totalReviews != null && totalReviews > 0) {
        const roundedScore = Math.round(reviewScore * 2) / 2;
        fullStars = Math.floor(roundedScore);
        hasHalfStar = roundedScore % 1 !== 0;
        emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);
    }

    return (
        <div className="review-stars-container">
             {reviewScore != null && totalReviews != null && totalReviews > 0 && (
                <p className="review-score-text">
                    {reviewScore.toFixed(1)} ({totalReviews})
                </p>
            )}
            <div className="review-stars">
                {Array.from({ length: fullStars }).map((_, index) => (
                    <img key={`full-${index}`} src="./img/yellow_star.svg" alt="Full Star" className="star" />
                ))}
                {hasHalfStar && <img src="./img/half_star.svg" alt="Half Star" className="star" />}
                {Array.from({ length: emptyStars }).map((_, index) => (
                    <img key={`empty-${index}`} src="./img/grey_star.svg" alt="Empty Star" className="star" />
                ))}
            </div>
          
        </div>
    );
};

export default ReviewPreviewStars;
