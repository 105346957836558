import React, { useState, useEffect, useRef } from 'react';
import { ref as databaseRef, onValue, off } from 'firebase/database';
import { database } from '../../firebaseConfig';
import './PageHeader.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { motion } from 'framer-motion';
import CountryFlagSVG2 from '../../Utility/CountryFlagEmoji2';

function PageHeader({ pageId, onToggleSettings, active, isLivePage }) {
  const [mediaItems, setMediaItems] = useState([]);
  const [gigImgUrl, setGigImgUrl] = useState(''); 
  const [artistImgUrl, setArtistImgUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const headerPlaceholderImage = '/img/background_2.jpg';
  const swiperRef = useRef(null);
  const [playing, setPlaying] = useState({}); 
  const [isHovering, setIsHovering] = useState({});
  const [showSoundIcon, setShowSoundIcon] = useState({});
  const [userData, setUserData] = useState({});
  const [artistId, setArtistId] = useState(null);
  const [isUserOnline, setIsUserOnline] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [representativeName, setRepresentativeName] = useState(''); // Neuer State für representativeName

  const handlePrevClick = (event) => {
    event.stopPropagation();
    if (swiperRef.current && swiperRef.current.slidePrev) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNextClick = (event) => {
    event.stopPropagation();
    if (swiperRef.current && swiperRef.current.slideNext) {
      swiperRef.current.slideNext();
    }
  };

  useEffect(() => {
    if (!pageId) return;

    const galleryRef = databaseRef(database, `/Pages/${pageId}/pageData/gallery`);
    const videoGalleryRef = databaseRef(database, `/Pages/${pageId}/pageData/videoGallery`);
    const pageArtistRef = databaseRef(database, `/Pages/${pageId}/artistId`);
    const representativeNameRef = databaseRef(database, `/Pages/${pageId}/pageData/gigRepresentative`);

    onValue(representativeNameRef, (snapshot) => {
      if (snapshot.exists()) {
        setRepresentativeName(snapshot.val());
      } else {
        setRepresentativeName(''); // Kein representativeName vorhanden
      }
    });

    const handleArtistData = (artistId) => {
      const artistDataRef = databaseRef(database, `/Artists/${artistId}`);
      
      onValue(artistDataRef, (artistDataSnapshot) => {
        if (artistDataSnapshot.exists()) {
          const artistData = artistDataSnapshot.val();
          setUserData(artistData);
        } else {
          setUserData({});
        }
      });

      const artistImgRef = databaseRef(database, `/Artists/${artistId}/artistImageUrl`);
      onValue(artistImgRef, (artistSnapshot) => {
        if (!gigImgUrl && artistSnapshot.exists()) {
          setArtistImgUrl(artistSnapshot.val());
        }
      });

      const userOnlineStatusRef = databaseRef(database, `/Artists/${artistId}/isUserOnline`);
      onValue(userOnlineStatusRef, (snapshot) => {
        setIsUserOnline(snapshot.val() === true);
      });
    };

    onValue(pageArtistRef, (snapshot) => {
      if (snapshot.exists()) {
        const artistId = snapshot.val();
        setArtistId(artistId);
        handleArtistData(artistId);
      }
    });

    const handleGalleryUpdate = snapshot => {
      if (snapshot.exists()) {
        const images = Object.values(snapshot.val()).map(url => ({ url, type: 'image' }));
        setMediaItems(prevItems => {
          const filteredItems = prevItems.filter(item => item.type !== 'image');
          return [...filteredItems, ...images];
        });
      } else {
        setMediaItems(prevItems => prevItems.filter(item => item.type !== 'image'));
      }
    };

    const handleVideoGalleryUpdate = snapshot => {
      if (snapshot.exists()) {
        const videos = Object.values(snapshot.val()).map(({ videoFile, videoThumbnail }) => ({
          videoFile,
          videoThumbnail,
          type: 'video'
        }));
        setMediaItems(prevItems => {
          const filteredItems = prevItems.filter(item => item.type !== 'video');
          return [...videos, ...filteredItems];
        });
      } else {
        setMediaItems(prevItems => prevItems.filter(item => item.type !== 'video'));
      }
    };

    onValue(galleryRef, handleGalleryUpdate);
    onValue(videoGalleryRef, handleVideoGalleryUpdate);

    return () => {
      off(pageArtistRef);
      off(galleryRef);
      off(videoGalleryRef);
      if (artistId) {
        const artistDataRef = databaseRef(database, `/Artists/${artistId}`);
        const artistImgRef = databaseRef(database, `/Artists/${artistId}/artistImageUrl`);
        const userOnlineStatusRef = databaseRef(database, `/Artists/${artistId}/isUserOnline`);
        off(artistDataRef);
        off(artistImgRef);
        off(userOnlineStatusRef);
      }
    };
  }, [pageId, artistId, gigImgUrl]);

  useEffect(() => {
    const galleryRef = databaseRef(database, `/Pages/${pageId}/pageData/gallery`);
    const videoGalleryRef = databaseRef(database, `/Pages/${pageId}/pageData/videoGallery`);

    const fetchMedia = async () => {
      const imagesPromise = new Promise((resolve, reject) => {
        onValue(galleryRef, (snapshot) => {
          if (snapshot.exists()) {
            const images = Object.values(snapshot.val()).map(url => ({ url, type: 'image' }));
            resolve(images);
          } else {
            resolve([{ url: headerPlaceholderImage, type: 'image' }]);
          }
        }, reject);
      });

      const videosPromise = new Promise((resolve, reject) => {
        onValue(videoGalleryRef, (snapshot) => {
          if (snapshot.exists()) {
            const videos = Object.values(snapshot.val()).map(({ videoFile, videoThumbnail }) => ({
              videoFile,
              videoThumbnail,
              type: 'video'
            }));
            resolve(videos);
          } else {
            resolve([]);
          }
        }, reject);
      });

      setIsLoading(true);
      Promise.all([videosPromise, imagesPromise])
        .then(([videos, images]) => {
          setMediaItems([...videos, ...images]); // Videos first, then images
          setIsLoading(false);
        })
        .catch(error => {
          console.error("Error fetching media: ", error);
          setIsLoading(false);
        });
    };

    fetchMedia();
    return () => {
      off(galleryRef);
      off(videoGalleryRef);
    };
  }, [pageId]);

  const togglePlayPause = (event, index) => {
    event.stopPropagation();

    const videoElement = swiperRef.current.slides[index].querySelector('video');

    if (!videoElement) {
      console.error(`No video element found at index ${index}.`);
      return;
    }

    if (videoElement.paused || videoElement.ended || videoElement.muted) {
      videoElement.currentTime = 0; // Restart video
      videoElement.muted = false; // Unmute when play button is clicked
      videoElement.play().then(() => {
        setPlaying({ ...playing, [index]: true });
        setShowSoundIcon({ ...showSoundIcon, [index]: false });
      }).catch(error => {
        console.error(`Error playing video at index ${index}: ${error.message}`);
      });
    } else {
      videoElement.pause();
      setPlaying({ ...playing, [index]: false });
      setShowSoundIcon({ ...showSoundIcon, [index]: false });
    }
  };

  const handleMouseEnter = (index) => {
    const videoElement = swiperRef.current.slides[index].querySelector('video');
    if (videoElement) {
      videoElement.muted = true; // Ensure the video is muted
      videoElement.play().then(() => {
        setPlaying({ ...playing, [index]: true });
        setIsHovering({ ...isHovering, [index]: true });
        setShowSoundIcon({ ...showSoundIcon, [index]: true });
      }).catch(error => {
        console.error(`Error playing video on hover at index ${index}: ${error.message}`);
      });
    }
  };

  const handleMouseLeave = (index) => {
    const videoElement = swiperRef.current.slides[index].querySelector('video');
    if (videoElement) {
      videoElement.pause();
      videoElement.currentTime = 0; // Reset video to the beginning
      setPlaying({ ...playing, [index]: false });
      setIsHovering({ ...isHovering, [index]: false });
      setShowSoundIcon({ ...showSoundIcon, [index]: false });
    }
  };

  const buttonVariants = {
    idle: {
      boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.6)"
    },
    press: {
      boxShadow: [
        "0px 0px 10px rgba(0, 0, 0, 0.5)",
        "0px 0px 20px rgba(0, 0, 0, 0.3)",
        "0px 0px 30px rgba(0, 0, 0, 0.2)",
        "0px 0px 40px rgba(0, 0, 0, 0.1)",
        "0px 0px 50px rgba(0, 0, 0, 0.05)",
        "0px 0px 60px rgba(0, 0, 0, 0)"
      ],
      transition: { duration: 0.6, yoyo: Infinity, ease: "easeInOut" }
    }
  };
  const iconVariants = {
    tap: {
      scale: 1.1,
      transition: {
        type: 'spring',
        stiffness: 700,
        damping: 10,
      }
    }
  };

  function handleImageLoaded() {
    document.querySelector('.profile-image-3').style.display = 'flex';
  }

  const imageToDisplay = gigImgUrl || artistImgUrl || 'URL_to_default_avatar.jpg';

  const className = `page-header-banner ${!isLivePage ? 'settings-route' : ''} ${active ? 'is-active' : ''}`;

  return (
    <div className='gig-page-header' onClick={() => onToggleSettings && onToggleSettings('PageHeader')}>
      <button
        onClick={handlePrevClick}
        className={`swiper-button-prev ${activeSlideIndex === 0 ? 'disabled' : ''}`}
        disabled={activeSlideIndex === 0}
      >
        <img src="../../img/left.svg" alt="Previous" />
      </button>
      <button
        onClick={handleNextClick}
        className={`swiper-button-next ${activeSlideIndex === mediaItems.length - 1 ? 'disabled' : ''}`}
        disabled={activeSlideIndex === mediaItems.length - 1}
      >
        <img src="../../img/right.svg" alt="Next" />
      </button>

      <div className={className}>
        <Swiper
          key={mediaItems.length}
          spaceBetween={50}
          slidesPerView={1}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          onSlideChange={(swiper) => {
            setActiveSlideIndex(swiper.activeIndex);
            const videos = document.querySelectorAll('.slide-video');
            Array.from(videos).forEach(video => {
              video.pause();
              video.currentTime = 0;
            });
            setPlaying({});
            setShowSoundIcon({});
          }}
        >
          {mediaItems.map((item, index) => (
            <SwiperSlide 
              key={item.url + index}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
            >
              {item.type === 'image' ? (
                <img src={item.url} alt={`Slide ${index}`} className="slide-image" />
              ) : (
                <div className="video-page-container">
                  <div className="slide-thumbnail-container" style={{ display: playing[index] ? 'none' : 'block' }}>
                    <img src={item.videoThumbnail} alt={`Thumbnail ${index}`} className="video-thumbnail-3" />
                  </div>
                  <video className="slide-video" style={{ display: playing[index] ? 'block' : 'none' }}>
                    <source src={item.videoFile} type="video/mp4" />
                    Ihr Browser unterstützt das Video-Tag nicht.
                  </video>
                  <motion.div
                    className="play-pause-btn-3"
                    onClick={(e) => togglePlayPause(e, index)}
                    variants={buttonVariants}
                    initial="idle"
                    whileTap="press"
                  >
                    <motion.img
                      src={playing[index] ? (showSoundIcon[index] ? "../../img/volume.svg" : "../../img/pause.svg") : "../../img/play.svg"}
                      alt={playing[index] ? (showSoundIcon[index] ? "Sound" : "Pause") : "Play"}
                      variants={iconVariants}
                      whileTap="tap"
                    />
                  </motion.div>
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="profile-section">
    {isLoading ? (
        <div className="spinner-2"></div>
    ) : (
        <div className="profile-image-container" style={{ position: 'relative' }}>
            {isUserOnline && (
                <div
                    className="online-status-background"
                >
                </div>
            )}
            {isUserOnline && (
                <p className="online-profile-banner">Online</p>
            )}
            <img
                src={imageToDisplay}
                onLoad={handleImageLoaded}
                alt={`${userData.artistName || "Unknown Artist"}'s profile`}
                className="profile-image-3"
                style={{ position: 'relative', zIndex: 2 }}
            />
        </div>
    )}
        {representativeName && <p style={{margin: '-10px 0px 0px 0px', fontSize: '15px', color: 'white', fontWeight: '600'}}>{representativeName} <span style={{fontWeight: '300'}}>for</span></p>}
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <h3 style={{marginTop: '0px'}}>{userData.artistName || "Unknown Artist"}</h3>
        <CountryFlagSVG2 country={userData.country}/>
    </div>
    <div className="profile-info">
        <span className="country">{userData.country || "Unknown Country"}</span>
        <hr/>
        <span className="name">{userData.artistType || userData.expertType || "Unknown Type"}</span>
        <hr/>
        <span className="genre">{Array.isArray(userData.genre) ? userData.genre.join(', ') : (userData.genre || "Unknown Genre")}</span>
    </div>
</div>
</div>
  );
}

export default PageHeader;
