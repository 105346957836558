import React, { useState, useEffect } from 'react';
import { ref, get, remove, update } from 'firebase/database';
import { database, auth } from '../../firebaseConfig';
import { Link, useNavigate } from 'react-router-dom';
import './AllPages.css';
import DashboardNav from '../DashboardNav';
import DeleteConfirmationModal from '../../Utility/DeleteConfirmationModal';
import { motion, AnimatePresence } from 'framer-motion';
import EmptyState from '../PageComps/EmptyState';
import PrevPage from './PrevPage';
import GenerativeOrbAnimation5 from '../../Utility/GenerativeOrbAnimation5';
import DashboardMobileNav from '../DashboardMobileNav';
import BuyProModal from '../../Pages/BuyProModal';

function AllPages() {
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageToDelete, setPageToDelete] = useState(null);
  const [isPremium, setIsPremium] = useState(false);
  const [isBuyProModalOpen, setIsBuyProModalOpen] = useState(false);

  const navigate = useNavigate(); // Verwende useNavigate

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = ref(database, `Artists/${user.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const { isPremium } = snapshot.val();
          setIsPremium(isPremium);
        }
      }
    };

    fetchUserData();
  }, []);

  const handleCreatePageClick = (event) => {
    event.preventDefault();
    if (!isPremium && pages.length > 0) {
      setIsBuyProModalOpen(true);
    } else {
      navigate('/create-page');
    }
  };

  const deletePage = async (pageId) => {
    if (!pageId) {
      alert('No page ID provided');
      return;
    }

    const pageRef = ref(database, `Pages/${pageId}`);

    try {
      const snapshot = await get(pageRef);
      const pageData = snapshot.val();
      if (!pageData) {
        alert('Page not found!');
        return;
      }

      const routeName = pageData.route; // Die Route der Seite
      await remove(pageRef);
      setPages(prevPages => prevPages.filter(page => page.id !== pageId));

      if (routeName) {
        const routeRef = ref(database, `PageRoutes/${routeName.toLowerCase()}`);
        await remove(routeRef);
      }

      setIsModalOpen(false);
      alert('Page deleted successfully!');
    } catch (error) {
      console.error('Error deleting page:', error);
      alert('Failed to delete the page.');
    }
  };

  const toggleLiveStatus = async (pageId, isCurrentlyLive) => {
    const updates = { pageIsLive: !isCurrentlyLive };
    const pageRef = ref(database, `Pages/${pageId}/pageData`);

    update(pageRef, updates)
      .then(() => {
        // Aktualisiere den Status in der lokalen State-Liste, um UI sofort zu aktualisieren
        setPages(prevPages => prevPages.map(page =>
          page.id === pageId ? { ...page, isLive: !isCurrentlyLive } : page
        ));
      })
      .catch(error => {
        console.error('Failed to update live status:', error);
      });
  };

  useEffect(() => {
    const fetchPages = async () => {
      if (auth.currentUser) {
        const artistID = auth.currentUser.uid;
        const pagesRef = ref(database, 'Pages');
  
        try {
          const snapshot = await get(pagesRef);
          const fetchedPages = [];
          snapshot.forEach((childSnapshot) => {
            const pageId = childSnapshot.key;
            const page = childSnapshot.val();
            if (page.artistId === artistID) {
              const firstGalleryImage = page.pageData?.gallery?.[0] || '../../img/demo-img.jpg';
              const route = page.route || '';
              const hasGigSettings = page.pageData?.hasGigSettings === true;
              const isLive = page.pageData?.pageIsLive || false;
  
              const service = page.pageData?.service;
              const serviceType = page.pageData?.serviceType;
              const payoutOption = page.pageData?.payoutOption;
              const profileImg = page.pageData?.profileImg;
              const offers = page.pageData?.offers || [];
              const earnAmount = offers.length > 0 ? offers[0].earnAmount : undefined;
              const gigTime = offers.length > 0 ? offers[0].gigTime : undefined;
              const gigRevisions = offers.length > 0 ? offers[0].gigRevisions : undefined;
              const gigTitle = offers.length > 0 ? offers[0].gigTitle : "";
              const gigText = offers.length > 0 ? offers[0].gigText : "";
              const clickCount = page.pageData?.clickCount || 0;
              const reviews = page.pageData?.reviews || {};
              const reviewCount = Object.keys(reviews).length;
  
              // Ensure pageCreated is a valid date
              let pageCreated = '';
              if (page.pageData?.pageCreated) {
                const createdDate = new Date(page.pageData.pageCreated);
                if (!isNaN(createdDate)) {
                  pageCreated = createdDate.toISOString();
                } else {
                  console.warn(`Invalid date value found for page ${pageId}: ${page.pageData.pageCreated}`);
                }
              }
  
              fetchedPages.push({
                id: pageId,
                firstGalleryImage,
                route,
                hasGigSettings,
                isLive,
                gigTitle,
                gigText,
                service,
                serviceType,
                payoutOption,
                earnAmount,
                gigTime,
                gigRevisions,
                clickCount,
                reviewCount,
                pageCreated,
                reviews,
                profileImg
              });
            }
          });
          setPages(fetchedPages);
        } catch (error) {
          console.error('Error fetching pages:', error);
        }
      }
      setLoading(false);
    };
  
    fetchPages();
  }, []);
  

  if (loading) {
    return (
      <div className="loading-state">
        <DashboardNav />
        <p>Loading...</p> {/* Hier könnte auch ein Spinner-Icon oder eine Animation eingefügt werden */}
      </div>
    );
  }

  return (
    <div className="all-pages-wrapper">
      <GenerativeOrbAnimation5 />
      <DashboardNav />
      <DashboardMobileNav />
      <DeleteConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => deletePage(pageToDelete)}
      />
      <div className="mySongsHeader">
        <h1>Art Links</h1>
        <Link to="#" onClick={handleCreatePageClick} className="upload-song-button">
          <p>Create Art Link</p>
          <img className="plus-song-icon" src="./img/plus_icon.webp" alt="Plus Icon" />
        </Link>
      </div>

      <AnimatePresence>
        {pages.length === 0 ? (
          <motion.div
            key="empty"
            initial={{ opacity: 0.5, scale: 1.01 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.99, transition: { duration: 0.1 } }}
          >
            <EmptyState />
          </motion.div>
        ) : (
          <motion.ul
            key="pages"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="all-pages-grid"
          >
            {pages.map(page => (
              <PrevPage key={page.id} page={page} onDelete={setPageToDelete} onToggleLive={toggleLiveStatus} />
            ))}
          </motion.ul>
        )}
      </AnimatePresence>

      {isBuyProModalOpen && <BuyProModal onClose={() => setIsBuyProModalOpen(false)} />}
    </div>
  );
}

export default AllPages;
