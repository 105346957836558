import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ref, onValue } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { database } from '../firebaseConfig';
import ArtistTop from './ArtistTop';
import HomeHeader from './HomeHeader';
import ArtistSongs from './ArtistSongs';
import SellArt from './SellArt';
import LoadingScreen from '../Utility/LoadingScreen';
import HomeNewHeader from './HomeNewHeader';
import NewMobileHeader from './NewMobileHeader';

const ArtistDetailPage = () => {
  const { artistID } = useParams();
  const [artistDetails, setArtistDetails] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const artistRef = ref(database, `Artists/${artistID}`);
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user);
    });

    onValue(artistRef, (snapshot) => {
      const data = snapshot.val();
      setArtistDetails(data);
    });

    return () => unsubscribe();
  }, [artistID]);

  if (!artistDetails) {
    // Verwende hier die LoadingScreen Komponente statt eines einfachen Textes
    return <LoadingScreen />;
  }

  return (
    <div>
      <HomeNewHeader/>
      <NewMobileHeader />
      <ArtistTop artistDetails={artistDetails} artistID={artistID} />
      {artistDetails && artistID && <SellArt artistId={artistID} />}
      <ArtistSongs artistID={artistID} />
      {/* Füge hier weitere Komponenten hinzu, die Künstlerdetails benötigen */}
    </div>
  );
};

export default ArtistDetailPage;
