import React from 'react';
import { Link } from 'react-router-dom';
import playIcon from '../img/play.svg';
import pauseIcon from '../img/pause.svg';
import VerticalText from '../Utility/VerticalText';
import CountryFlagEmoji from '../Utility/CountryFlagEmoji';
import './CollabSongs.css';

function CollabSong({
  song,
  isPlaying,
  currentSong,
  togglePlayPause,
  openCollabModal,
  existingChats,
  saveCollabFavorite,
  likeSong,
  songRef
}) {
  return (
    <div
      ref={songRef}
      className={`collab-song ${isPlaying && currentSong && currentSong.id === song.id ? 'playing' : ''}`}
      style={existingChats[song.artistID] ? { opacity: 1, position: 'relative' } : {}}
    >
      {existingChats[song.artistID] && (
        <div className="collab-contacted-overlay">
          <img src="./img/check_4.webp" alt="Contacted" />
          <p>Contacted</p>
        </div>
      )}
      <div className="collab-song-top">
        {!existingChats[song.artistID] && (
          <button className="collab-contact-artist-button" onClick={() => openCollabModal(song.artistID)}>
            <p>Connect</p>
            <img src="./img/link-to-device.svg" alt="Collaborate" />
          </button>
        )}
        <button 
          className="collab-contact-like-button"
          onClick={() => {
            likeSong(song.id);
            saveCollabFavorite(song.id, song.artistID);
          }}
        >
          <img
            src={song.hasUserLiked ? './img/herzibinki.svg' : './img/love.svg'}
            alt={song.hasUserLiked ? 'Liked' : 'Unliked'}
            style={{
              filter: song.hasUserLiked ? 'none' : 'invert(1)',
            }}
          />
          <p>{song.totalLikes}</p>
        </button>
        <a href={`/song/${song.id}`} target="_blank" rel="noopener noreferrer">
  <img src={song.artistImageUrl || song.songCoverUrl} alt={song.name} className="collab-song-img" />
</a>
        {(song.numberAwards > 0 || song.numberNominated > 0) && (
          <div className="award-ribbon" style={{ marginTop: '-20px', left: '14px', zIndex: '100' }}>
            <img src="../img/icon.png" alt="Award Ribbon" />
            <VerticalText text={song.numberAwards > 0 ? "AWARDED" : "NOMINEE"} />
          </div>
        )}
      </div>
      <div className="collab-song-bottom">
        <div className="collab-song-bottom-bg" style={{ backgroundImage: `url(${song.artistImageUrl || song.songCoverUrl})` }}></div>
        <div className="collab-song-content">
          <Link to={`/song/${song.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <h4 style={{ marginLeft: '10px' }}>{song.name}</h4>
          </Link>
          <div>
            <Link to={`/artist/${song.artistID}`} style={{ textDecoration: 'none', color: 'white', display: 'flex' }}>
              <p style={{ marginLeft: '10px' }}>
                {song.artist}
              </p>
            </Link>
            <div className="countryflag-emoji-text">
              {song.selectedCountry && <CountryFlagEmoji country={song.selectedCountry} />}
            </div>
            <div className="song-genre-collab-info">
              <p style={{ fontSize: '14px' }}>{song.genre}</p>
            </div>
          </div>
        </div>
      </div>
      <button className="play-pause-button-3" onClick={() => togglePlayPause(song)}>
        <div className="song-cover-circle" style={{ backgroundImage: `url(${song.songCoverUrl})` }}></div>
        {isPlaying && currentSong && currentSong.id === song.id ? (
          <img src={pauseIcon} className="pause-icon" alt="Pause" />
        ) : (
          <img src={playIcon} className="play-icon" alt="Play" />
        )}
      </button>
    </div>
  );
}

export default CollabSong;
