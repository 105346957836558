import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import './BuyProModal.css';

// Lade den Stripe-Client mit deinem veröffentlichen API-Schlüssel
const stripePromise = loadStripe('pk_live_51PrkwWHh3Mj0zlrOGZarHk6nLSDajyp7hjYCDgblAI5mNtQgr8UwROb2XtaNXVrQHQ8EyYGCbWXb08yogHCZVAk400kTijR8Hb');

const BuyProModal = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState('yearly'); // Standardmäßig 'yearly' ausgewählt

  const handleCheckout = async () => {
    if (selectedPackage !== 'yearly') return;

    setLoading(true);
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
        const functions = getFunctions();
        const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
        
        try {
            // Hier wird die `priceId` korrekt gesetzt und an die Funktion übergeben
            const { data } = await createCheckoutSession({ userId: user.uid, priceId: 'price_1PrlR5Hh3Mj0zlrOvLEs5o0a' });
            const stripe = await stripePromise;
            const { error } = await stripe.redirectToCheckout({ sessionId: data.sessionId });

            if (error) {
                console.error("Stripe checkout error", error);
            }
        } catch (error) {
            // Hier geben wir den genauen Fehler als Log aus, um mehr Informationen zu erhalten
            console.error("Checkout session error:", error.message);
            console.error("Stack trace:", error.stack);
        }
    } else {
        console.error("User is not logged in");
    }

    setLoading(false);
    onClose(); // Schließen Sie das Modal nach dem Starten des Checkouts
};


  const handlePromotion = () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const email = user.email;
      const message = `User ${user.uid} (${email}) is interested in the Custom Release Package.`;
      console.log(message);

      // Sie können eine Funktion aufrufen, um die E-Mail zu senden
      // oder einen API-Aufruf, um das Interesse zu erfassen.
      alert("Anfrage gesendet. Wir werden uns in Kürze bei Ihnen melden.");
    } else {
      alert("Bitte melden Sie sich an, um fortzufahren.");
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="buy-pro-modal-overlay" onClick={handleOverlayClick}>
      <div className="buy-pro-modal-content">
        <div className="buy-pro-modal-body">
          <div className="buy-pro-modal-left">
            <h2>Upgrade to Pro</h2>
            <p>Choose the plan that suits you best and unlock exclusive features.</p>

            <div className="buy-pro-features">
              {selectedPackage === 'free' && (
                <>
                  <div>
                    <img src="../../img/check_4.webp" alt="Feature" />
                    <span>Upload 1 Song</span>
                  </div>
                  <div>
                    <img src="../../img/check_4.webp" alt="Feature" />
                    <span>Award Approval Notification</span>
                  </div>
                  <div>
                    <img src="../../img/check_4.webp" alt="Feature" />
                    <span>3 Connection Credits</span>
                  </div>
                </>
              )}
              {selectedPackage === 'yearly' && (
                <>
                  <div>
                    <img src="../../img/check_4.webp" alt="Feature" />
                    <span>Unlimited Song Uploads</span>
                  </div>
                  <div>
                    <img src="../../img/check_4.webp" alt="Feature" />
                    <span>Award Approval & Decline Notifcation</span>
                  </div>
                  <div>
                    <img src="../../img/check_4.webp" alt="Feature" />
                    <span>Unlimited Connections</span>
                  </div>
                  <div>
                    <img src="../../img/check_4.webp" alt="Feature" />
                    <span>Apply for Promotion Banners</span>
                  </div>
                </>
              )}
              {selectedPackage === 'promotion' && (
                <>
                  <div>
                    <img src="../../img/check_4.webp" alt="Feature" />
                    <span>1 Single Release</span>
                  </div>
                  <div>
                    <img src="../../img/check_4.webp" alt="Feature" />
                    <span>15+ Magazine Features</span>
                  </div>
                  <div>
                    <img src="../../img/check_4.webp" alt="Feature" />
                    <span>15+ Social Media Shoutouts</span>
                  </div>
                  <div>
                    <img src="../../img/check_4.webp" alt="Feature" />
                    <span>AAA Vip Membership</span>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="buy-pro-modal-right">
            <div className="buy-pro-modal-price-options">
              <div
                className={`buy-pro-option ${selectedPackage === 'free' ? 'selected' : ''}`}
                onClick={() => setSelectedPackage('free')}
              >
                <div>
                  <h4>Free</h4>
                  <span></span>
                </div>
                <img src="../../img/check_4.webp" alt="Selected" />
              </div>
              <div
                className={`buy-pro-option ${selectedPackage === 'yearly' ? 'selected' : ''}`}
                onClick={() => setSelectedPackage('yearly')}
              >
                <div>
                  <h4>VIP Membership</h4>
                  <span>79,00 $/Yearly</span>
                </div>
                <img src="../../img/check_4.webp" alt="Selected" />
              </div>
              <div
                className={`buy-pro-option ${selectedPackage === 'promotion' ? 'selected' : ''}`}
                onClick={() => setSelectedPackage('promotion')}
              >
                <div>
                  <h4>Custom Release Package</h4>
                  <span>2.490,00 $</span>
                </div>
                <img src="../../img/check_4.webp" alt="Selected" />
              </div>
            </div>

            <div className="buy-pro-total">
              <span style={{ fontWeight: '500' }}>Total:</span>
              <span>
                {selectedPackage === 'free' && '0,00 $'}
                {selectedPackage === 'yearly' && '79,00 $'}
                {selectedPackage === 'promotion' && '2.490,00 $'}
              </span>
            </div>

            <div className="buy-pro-modal-buttons">
  {selectedPackage === 'yearly' && (
    <button 
      className="buy-pro-modal-checkout-button"
      onClick={handleCheckout}
      disabled={loading}
    >
      {loading ? (
        <>
          Proceeding...
          <div className="buy-pro-modal-button-spinner"></div>
        </>
      ) : (
        'Proceed to Checkout'
      )}
    </button>
  )}
  {selectedPackage === 'free' && (
    <button className="buy-pro-modal-checkout-button disabled" disabled>
      Free Package
    </button>
  )}
  {selectedPackage === 'promotion' && (
    <button 
      className="buy-pro-modal-checkout-button"
      onClick={handlePromotion}
    >
      Contact Us
    </button>
  )}
  <div>
    <img src="../../img/stripe_secure_2.webp" alt="Stripe Secure Payment" />
  </div>
  <button className="buy-pro-modal-close-button" onClick={onClose}>
    <img src="../../img/plus.svg" alt="Close" />
  </button>
</div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default BuyProModal;
