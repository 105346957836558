import React, { useState, useEffect } from 'react';
import { ref, onValue, get } from 'firebase/database';
import { database } from '../firebaseConfig';
import './SellArt.css';
import CreateCollabModal from './CreateCollabModal';
import BuyProModal from './BuyProModal';
import CollabLoginPopup from './CollabLoginPopup';
import FinishSettingsModal from './FinishSettingsModal';
import CollabSuccessModal from './CollabSuccessModal'; // Success Modal importieren
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const serviceIcons = {
  sellBeats: '../img/music.svg',
  sellLicense: '../img/auction.svg',
  sellVerse: '../img/verses.svg',
  openForCollab: '../img/waves.svg',
};

const serviceTitles = {
  sellBeats: 'Beats for Sale',
  sellLicense: 'Licenses Available',
  sellVerse: 'Custom Verses',
  openForCollab: 'Open for Collaboration',
};

const serviceDescriptions = {
  sellBeats: 'Purchase exclusive beats.',
  sellLicense: 'Buy a license for commercial use.',
  sellVerse: 'Get a custom verse for your track.',
  openForCollab: 'Open for collaborations.',
};

const SellArt = ({ artistId }) => {
  const [artistServices, setArtistServices] = useState({});
  const [artistImageUrl, setArtistImageUrl] = useState(''); // Hier speichern wir das Bild
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showCollabModal, setShowCollabModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showFinishSettingsModal, setShowFinishSettingsModal] = useState(false);
  const [showCollabLoginPopup, setShowCollabLoginPopup] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false); // State für Success Modal
  const [userCredits, setUserCredits] = useState(0);
  const [isPremium, setIsPremium] = useState(false);
  const [hasSettings, setHasSettings] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [isChatExists, setIsChatExists] = useState(false);

  useEffect(() => {
    if (!artistId) return;

    const artistRef = ref(database, `/Artists/${artistId}`);
    onValue(artistRef, (snapshot) => {
      const data = snapshot.val() || {};
      setArtistServices({
        sellBeats: data.sellBeats || false,
        sellLicense: data.sellLicense || false,
        sellVerse: data.sellVerse || false,
        openForCollab: data.openForCollab || false,
      });

      if (data.artistImageUrl) {
        setArtistImageUrl(data.artistImageUrl); // Bild-URL speichern
      }
    });
  }, [artistId]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsLoggedIn(true);
        setCurrentUserId(user.uid);

        const userRef = ref(database, `Artists/${user.uid}`);
        const userSnapshot = await get(userRef);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.val();
          setUserCredits(userData.collabCredits || 0);
          setIsPremium(userData.isPremium || false);
          setHasSettings(userData.hasSettings || false);
        }

        // Check if a chat already exists between the current user and the artist
        checkChatExists(user.uid, artistId);
      } else {
        setIsLoggedIn(false);
        setCurrentUserId(null);
      }
    });

    return () => unsubscribe();
  }, [artistId]);

  const checkChatExists = async (currentUserId, artistID) => {
    const chatId1 = `${currentUserId}_${artistID}`;
    const chatId2 = `${artistID}_${currentUserId}`;

    const chatRef1 = ref(database, `Chats/${chatId1}`);
    const chatRef2 = ref(database, `Chats/${chatId2}`);

    const chatSnapshot1 = await get(chatRef1);
    const chatSnapshot2 = await get(chatRef2);

    if (chatSnapshot1.exists() || chatSnapshot2.exists()) {
      setIsChatExists(true);
    }
  };

  const handleServiceClick = (serviceKey) => {
    if (isChatExists) return;

    if (!isLoggedIn) {
      setShowCollabLoginPopup(true);
      return;
    }

    if (hasSettings === false) {
      setShowFinishSettingsModal(true);
      return;
    }

    if (isPremium || userCredits > 0) {
      setShowCollabModal(true);
    } else {
      setShowUpgradeModal(true);
    }
  };

  const handleSuccessMessage = async () => {
    setShowSuccessModal(true);
    await checkChatExists(currentUserId, artistId); // Überprüfe den Chatstatus erneut, um den Button zu sperren
    setTimeout(() => {
      setShowSuccessModal(false);
    }, 3000);
  };

  const serviceEntries = Object.entries(artistServices).filter(([key, value]) => value);

  if (serviceEntries.length === 0) {
    return null; // Keine Services, keine Anzeige
  }

  return (
    <div className="sell-art">
      <h3>Opportunities</h3>
      <div className="sell-art-container">
        {serviceEntries.map(([key, value], index) => (
          <div 
            key={index} 
            className="sell-art-item" 
            onClick={() => handleServiceClick(key)}
            style={{ cursor: isChatExists ? 'not-allowed' : 'pointer', opacity: isChatExists ? 0.6 : 1 }} // Button-Stil anpassen
          >
            <img src={serviceIcons[key]} alt={serviceTitles[key]} className="service-icon" />
            <div className="service-text">
              <h4>{serviceTitles[key]}</h4>
              <p>{serviceDescriptions[key]}</p>
            </div>
            <img style={{ right: '10px', opacity: '0.5' }} className="forward_icon_sell" src="../img/forward.svg" alt="" />
          </div>
        ))}
      </div>

      {showCollabModal && currentUserId && (
        <CreateCollabModal
          senderId={currentUserId}
          receiverId={artistId}
          receiverImageUrl={artistImageUrl} // Übergibt die Bild-URL an das Modal
          onClose={() => setShowCollabModal(false)}
          onMessageSent={(receiverId) => {
            console.log(`Message sent to ${receiverId}`);
            setShowCollabModal(false);
            handleSuccessMessage(); // Zeige die Erfolgsmeldung an und blockiere den Button
          }}
        />
      )}
      {showUpgradeModal && (
        <BuyProModal onClose={() => setShowUpgradeModal(false)} />
      )}
      {showFinishSettingsModal && (
        <FinishSettingsModal onClose={() => setShowFinishSettingsModal(false)} />
      )}
      {showCollabLoginPopup && (
        <CollabLoginPopup onClose={() => setShowCollabLoginPopup(false)} />
      )}
      {showSuccessModal && (
        <CollabSuccessModal onClose={() => setShowSuccessModal(false)} />
      )}
    </div>
  );
};

export default SellArt;
