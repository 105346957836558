import React, { createContext, useContext, useState, useEffect } from 'react';
import { ref as databaseRef, get } from 'firebase/database';
import { database, auth } from '../firebaseConfig';

const ChatContext = createContext();

export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }) => {
    const [unreadCounts, setUnreadCounts] = useState({});
    const [totalUnread, setTotalUnread] = useState(0);
    const [currentUser, setCurrentUser] = useState(null);
    const [isAuthLoading, setIsAuthLoading] = useState(true); // Zustand, der anzeigt, ob die Authentifizierung noch lädt

    useEffect(() => {
        // Listener für Authentifizierungsstatus
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
            setIsAuthLoading(false); // Authentifizierungsstatus ist geladen
        });

        return () => unsubscribe(); // Cleanup-Funktion
    }, []);

    useEffect(() => {
        if (isAuthLoading) return; // Warten, bis der Authentifizierungsstatus geladen ist
        if (!currentUser) {
            console.log("No user logged in.");
            return;
        }

        const chatsRef = databaseRef(database, 'Chats');
        const fetchChats = async () => {
            const snapshot = await get(chatsRef);
            if (snapshot.exists()) {
                const chatData = snapshot.val();

                const unreadPromises = Object.keys(chatData)
                    .filter(key => chatData[key].initiatorId === currentUser.uid || chatData[key].recipientId === currentUser.uid)
                    .map(async key => {
                        const messagesRef = databaseRef(database, `Chats/${key}/messages`);
                        const messagesSnapshot = await get(messagesRef);
                        let unreadCount = 0;
                        if (messagesSnapshot.exists()) {
                            const messages = messagesSnapshot.val();
                            unreadCount = Object.values(messages).reduce((acc, message) => (
                                acc + (!message.readStatus && message.senderId !== currentUser.uid ? 1 : 0)
                            ), 0);
                        }
                        return { key, unreadCount };
                    });

                const results = await Promise.all(unreadPromises);
                const newUnreadCounts = results.reduce((acc, { key, unreadCount }) => {
                    acc[key] = unreadCount;
                    return acc;
                }, {});

                setUnreadCounts(newUnreadCounts);
                setTotalUnread(results.reduce((total, { unreadCount }) => total + unreadCount, 0));
            }
        };

        fetchChats();
    }, [currentUser, isAuthLoading]); // Abhängigkeit auch von isAuthLoading

    return (
        <ChatContext.Provider value={{ unreadCounts, setUnreadCounts, totalUnread }}>
            {children}
        </ChatContext.Provider>
    );
};
