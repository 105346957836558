import React, { useState, useEffect } from 'react';
import { auth } from '../firebaseConfig';
import { ref as databaseRef, push, get } from 'firebase/database';
import { database } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import BuyProModal from './BuyProModal'; // Importiere das Modal
import './CollabPage.css';

const buttonVariants = [
  { id: 1, text: "Apply to Connect" },
  { id: 2, text: "Join to Collaborate" },
  { id: 3, text: "Upload Your Demo" }
];

function CollabUnlockButton() {
  const [user, setUser] = useState(null);
  const [isPremium, setIsPremium] = useState(false);
  const [credits, setCredits] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [buttonVariant, setButtonVariant] = useState(buttonVariants[0]); // Setze die Standardvariante
  const navigate = useNavigate();

  useEffect(() => {
    // Zufällige Auswahl eines Button-Textes beim Laden der Komponente
    const randomVariantIndex = Math.floor(Math.random() * buttonVariants.length);
    setButtonVariant(buttonVariants[randomVariantIndex]);

    const fetchUserData = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        setUser(currentUser);
        const userRef = databaseRef(database, `Artists/${currentUser.uid}`);
        const userSnapshot = await get(userRef);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.val();
          setIsPremium(userData.isPremium || false);
          setCredits(userData.collabCredits || 0);
        }
      }
    };

    fetchUserData();
  }, []);

  const handleButtonClick = () => {
    if (!user) {
      // Push die Button-Daten in die Firebase-Datenbank, wenn der Benutzer nicht eingeloggt ist
      const clickData = {
        variantId: buttonVariant.id,
        buttonText: buttonVariant.text,
        timestamp: Date.now()
      };

      const analyticsRef = databaseRef(database, '/Analytics/CollabTopButton');
      push(analyticsRef, clickData); // Pusht die Daten in die Firebase-Realtime-Datenbank

      // Navigiere zur Signup-Seite
      navigate('/signup');
    } else if (!isPremium) {
      setShowModal(true);
    }
  };

  const renderButtonContent = () => {
    if (!user) {
      return (
        <>
          <h5>{buttonVariant.text}</h5>
        </>
      );
    }

    if (isPremium) {
      return (
        <>
          <h5>PRO ACCOUNT</h5>
        </>
      );
    }

    return (
      <>
        <h5>UNLOCK MORE</h5>
        <img src="./img/locky.svg" alt="Unlock More" />
      </>
    );
  };

  return (
    <div className="collab-page-right">
      {!user ? (
        <p>Collab with Pros</p>
      ) : !isPremium ? (
        <p>{credits} Credits Available</p>
      ) : null}
      
      <button onClick={handleButtonClick}>
        {renderButtonContent()}
      </button>

      {/* Modal für BuyPro */}
      {showModal && <BuyProModal onClose={() => setShowModal(false)} />}
    </div>
  );
}

export default CollabUnlockButton;
