import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onValue, ref, off } from 'firebase/database';
import { auth, database } from '../firebaseConfig';
import './DashboardPage.css';
import LoadingScreen from '../Utility/LoadingScreen';
import SubmissionScreen from './SubmissionScreen';
import ArtistDashboard from './ArtistDashboard';

function DashboardPage() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(currentUser => {
      if (currentUser) {
        const artistRef = ref(database, `/Artists/${currentUser.uid}`);
        const listener = onValue(artistRef, (snapshot) => {
          const artistData = snapshot.val();
          if (artistData) {
            if (artistData.hasSettings || artistData.hasExpertSettings) {
              setUser(artistData); // Speichere das gesamte Artist-Objekt im State
            } else {
              navigate('/welcome'); // Navigiere zur Welcome-Seite, falls die Einstellungen nicht vollständig sind
            }
          } else {
            navigate('/login'); // Navigiere zum Login, falls keine Daten vorhanden sind
          }
        });

        return () => {
          off(artistRef, 'value', listener); // Detach the listener when the component unmounts or auth changes
        };
      } else {
        navigate('/signup');
      }
    });

    return () => {
      unsubscribe(); // This will detach the onAuthStateChanged listener
    };
  }, [navigate]);
  
  if (!user) {
    return <div>Loading...</div>; // oder eine andere Art von Ladeanzeige/Behandlung
  }

  // Experten sollen den SubmissionScreen nicht sehen, wenn hasExpertSettings true ist.
  if (user.isExpert && user.hasExpertSettings) {
    return (
      <div className="dashboard-page">
        <ArtistDashboard user={user} />
      </div>
    );
  }

  // Normale Nutzer oder Experten ohne abgeschlossene Einstellungen
  return (
    <div className="dashboard-page">
      {user.hasSubmitted ? (
        <ArtistDashboard user={user} />
      ) : (
        <SubmissionScreen user={user} />
      )}
    </div>
  );
}

export default DashboardPage;
