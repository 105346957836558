import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import logo from '../img/logo_2.png';
import { useAppContext } from '../Utility/AppContext';
import './HomeNewHeader.css';
import { useChat } from '../Utility/ChatContext';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Importiere Firebase Auth
import { ref as databaseRef, push } from 'firebase/database'; // Firebase DB import
import { database } from '../firebaseConfig'; // Annahme: Firebase ist hier konfiguriert

// Icons importieren
import connectActiveIcon from '../img/link-to-device.svg';
import marketActiveIcon from '../img/shopper_3.svg';

const buttonVariants = [
  { text: "Apply Here" },
  { text: "Join Now" },
  { text: "Submit Song" },
  { text: "Sign Up Free" },
  { text: "Join AAA" },
  { text: "Submit Track" },
];

function HomeNewHeader() {
  const { userData } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Neuer Zustand für Login-Status
  const [buttonVariant, setButtonVariant] = useState(buttonVariants[0]); // Button Text Variants
  const { totalUnread } = useChat();
  const location = useLocation(); // Ermöglicht das Überprüfen der aktuellen Route
  
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    const randomVariant = buttonVariants[Math.floor(Math.random() * buttonVariants.length)];
    setButtonVariant(randomVariant);
    setIsLoading(false);

    return () => unsubscribe();
  }, []);

  const avatarImage = !isLoading && userData.artistImageUrl ? userData.artistImageUrl : "../img/avatar.svg";

  const handleClick = () => {
    const clickData = {
      variant: buttonVariant.text,
      timestamp: Date.now(),
    };

    const analyticsRef = databaseRef(database, '/Analytics/applyButtonClicks');
    push(analyticsRef, clickData);

    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'apply_button_click',
        variant: buttonVariant.text,
      });
    }

    window.open('/signup', '_blank');
  };

  return (
    <div className="home-new-header">
      <div className="header-new-left">
        <NavLink to="/">
          <img src={logo} alt="Logo" className="header-new-logo" />
        </NavLink>
      </div>

      <div className="header-new-middle">
        <NavLink to="/collaborations" className="toggler-button" activeclassname="active">
          {location.pathname === '/collaborations' && (
            <img src={connectActiveIcon} alt="Connect" className="menu-icon" />
          )}
          Connect
        </NavLink>
        <NavLink to="/" id="header-awards-button" className="toggler-button" activeclassname="active">
          Awards
        </NavLink>
        <NavLink to="/market" className="toggler-button" activeclassname="active">
          {location.pathname.startsWith('/market') && (
            <img src={marketActiveIcon} alt="Market" className="menu-icon" />
          )}
          Market
        </NavLink>
      </div>

      {userData.isPremium && (
        <p className="header-pro-right-pro">Pro</p>
      )}

      {!isLoggedIn && (
        <div className="apply-for-free-header-button">
          <button onClick={handleClick}>
            <p>{buttonVariant.text}</p>
          </button>
        </div>
      )}

      <div className="header-new-right">
        <NavLink to="/favorites">
          <img style={{ width: '17px', marginLeft: '8px', filter: 'invert(1)' }} src="../../img/love_2.svg" alt="" />
        </NavLink>
        <NavLink to="/chats">
          <div className="chat-notification">
            <img style={{ width: '20px', filter: 'invert(1)' }} src="../../img/chat_2.svg" alt="" />
            {totalUnread > 0 && (
              <div className="total-unread-messages-menu-2">{totalUnread}</div>
            )}
          </div>
        </NavLink>
        <NavLink to="/dashboard">
          <img src={avatarImage} alt="Avatar" className="header-avatar" />
        </NavLink>
      </div>
    </div>
  );
}

export default HomeNewHeader;
