import React from 'react';
import './GenerativeOrbAnimation.css';

const GenerativeOrbAnimation4 = () => {
  // Dies ist nur eine Präsentationskomponente, die keine Logik enthält.
  return (
<div class="component-wrapper">
    <div className="orb4 orb4-1"></div>
    <div className="orb4 orb4-2"></div>
    <div className="orb4 orb2-3"></div>
    <div className="orb4 orb2-4"></div>
</div>

  );
};

export default GenerativeOrbAnimation4;
