import React, { useEffect, useState } from 'react';
import { ref as databaseRef, onValue, remove, update } from 'firebase/database';
import { database } from "../firebaseConfig";
import './ListArtists.css'; // Stellen Sie sicher, dass der Pfad korrekt ist
import EditArtistModal from './EditArtistModal';
import ArtistStats from './ArtistStats';
import ServiceStats from './ServiceStats';
import ListArtistActions from './ListArtistActions';

function ListArtists() {
  const [artists, setArtists] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentArtistId, setCurrentArtistId] = useState(null);
  const [isActionModalOpen, setIsActionModalOpen] = useState(false); // Modal für Aktionen
  const [currentArtist, setCurrentArtist] = useState(null); // Aktueller Künstler für das Aktionsmodal
  
  const openEditModal = (artistId) => {
    setCurrentArtistId(artistId);
    setIsEditModalOpen(true);
  };

  const openActionModal = (artist) => {
    setCurrentArtist(artist);
    setIsActionModalOpen(true);
  };

  const closeActionModal = () => {
    setIsActionModalOpen(false);
    setCurrentArtist(null);
  };

  const deleteArtist = (artistId) => {
    const isConfirmed = window.confirm("Sind Sie sicher, dass Sie diesen Künstler löschen möchten?");
    if (isConfirmed) {
      const artistRef = databaseRef(database, `Artists/${artistId}`);
      remove(artistRef)
        .then(() => {
          console.log(`Künstler ${artistId} erfolgreich gelöscht.`);
        })
        .catch((error) => {
          console.error("Fehler beim Löschen des Künstlers: ", error);
        });
    }
  };

  const togglePremiumStatus = (artist) => {
    const updatedStatus = !artist.isPremium;
    const artistRef = databaseRef(database, `Artists/${artist.id}`);
    update(artistRef, { isPremium: updatedStatus })
      .then(() => {
        console.log(`Premium-Status für ${artist.artistName} erfolgreich auf ${updatedStatus ? 'Ja' : 'Nein'} gesetzt.`);
      })
      .catch((error) => {
        console.error("Fehler beim Aktualisieren des Premium-Status: ", error);
      });
  };
  
  useEffect(() => {
    const artistsRef = databaseRef(database, 'Artists');
    onValue(artistsRef, (snapshot) => {
      const artistsData = snapshot.val();
      let artistsList = artistsData ? Object.keys(artistsData).map(key => ({
        id: key,
        ...artistsData[key],
      })) : [];
  
      artistsList.sort((a, b) => {
        return new Date(b.signupTimestamp) - new Date(a.signupTimestamp);
      });
  
      setArtists(artistsList);
    });
  }, []);
  
  return (
    <div className="list-artists-container">
      <ArtistStats />
      <ServiceStats />
      <h1>Künstlerliste</h1>
      <div className="table-scroll-container">
      <table className="list-artists-table">
        <thead>
          <tr>
          {isEditModalOpen && <EditArtistModal artistId={currentArtistId} onClose={() => setIsEditModalOpen(false)} />}
          {isActionModalOpen && currentArtist && (
            <ListArtistActions
              artist={currentArtist}
              onClose={closeActionModal}
              onSave={(updatedArtist) => {
                // Aktualisiere die Künstlerliste nach dem Speichern
                setArtists(artists.map(a => a.id === updatedArtist.id ? updatedArtist : a));
                closeActionModal();
              }}
            />
          )}
            <th>Edit Artist</th>
            <th>Künstlername</th>
            <th>Name</th>
            <th>Land</th>
            <th>Email</th>
            <th>Settings</th>
            <th>Premium</th>
            <th>Song Credits</th> {/* Neue Spalte für Song Credits */}
            <th>Collab Credits</th> {/* Neue Spalte für Collab Credits */}
            <th>Letzter Zugriff</th>
            <th>Letztes Login</th>
            <th>Auszeichnungen</th>
            <th>Nominiert</th>
            <th>Sitzungen</th>
            <th>Registrierungsdatum</th>
            <th>ID</th>
            <th>Aktionen</th> {/* Neue Spalte für Aktionen */}
            <th>Löschen</th>
          </tr>
        </thead>
        <tbody>
          {artists.map((artist) => (
            <tr key={artist.id}>
              <td><button onClick={() => openEditModal(artist.id)}>Edit Artist</button></td>
              <td>{artist.artistName}</td>
              <td>{artist.name}</td>
              <td>{artist.country}</td>
              <td>{artist.email}</td>
              <td>{artist.hasSettings ? 'Ja' : 'Nein'}</td>
              <td>
                <label className="switch">
                  <input 
                    type="checkbox" 
                    checked={artist.isPremium} 
                    onChange={() => togglePremiumStatus(artist)} 
                  />
                  <span className="slider round"></span>
                </label>
              </td>
              <td>{artist.songCredits || 0}</td> {/* Anzeige von Song Credits */}
              <td>{artist.collabCredits || 0}</td> {/* Anzeige von Collab Credits */}
              <td>{artist.lastAccess}</td>
              <td>{artist.lastLogin}</td>
              <td>{artist.numberAwards}</td>
              <td>{artist.numberNominated}</td>
              <td>{artist.sessionCount}</td>
              <td>{artist.signupTimestamp}</td>
              <td>{artist.id}</td>
              <td><button onClick={() => openActionModal(artist)}>Aktionen</button></td>
              <td><button onClick={() => deleteArtist(artist.id)} style={{ marginLeft: "10px"}}>Löschen</button></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
  );
}

export default ListArtists;
