import { useEffect } from 'react';
import { ref as databaseRef, set, onDisconnect } from 'firebase/database';
import { auth, database } from '../firebaseConfig'; // Pfad entsprechend anpassen

const useUserOnlineStatus = () => {
  useEffect(() => {
    const updateOnlineStatus = async () => {
      const user = auth.currentUser;

      if (user && user.emailVerified) {
        const userStatusRef = databaseRef(database, `/Artists/${user.uid}/isUserOnline`);
        
        try {
          // Setze den Online-Status auf "true" bei Verbindungsaufbau (z.B. Laden der App)
          await set(userStatusRef, true);

          // Konfiguriere `onDisconnect`, um den Status auf "false" zu setzen, wenn die Verbindung verloren geht
          onDisconnect(userStatusRef).set(false);
        } catch (error) {
          console.error("Fehler beim Setzen des isUserOnline-Status:", error);
        }
      }
    };

    // Initiales Setzen des Status beim Laden der App
    updateOnlineStatus();

    // Auth-Statusänderungen überwachen und den Status sofort aktualisieren
    const unsubscribe = auth.onAuthStateChanged((user) => {
      updateOnlineStatus();
    });

    // Periodisches Aktualisieren des Online-Status alle 30 Sekunden
    const intervalId = setInterval(updateOnlineStatus, 30 * 1000); // 30 Sekunden

    return () => {
      clearInterval(intervalId); // Bereinigen des Intervalls beim Verlassen der Komponente
      unsubscribe(); // Abmelden vom Auth-Listener
    };
  }, []);
};

export default useUserOnlineStatus;
