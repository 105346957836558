import React, { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from "../firebaseConfig";
import AdminAddSong from './AdminAddSong';
import ListSongs from './ListSongs';
import ListArtists from './ListArtists';
import SpotlightArtist from './SpotlightArtist';
import './TeamDashboard.css';
import AwardCreator from './AwardCreator';
import ListExperts from './ListExperts';
import ListPages from './ListPages';
import Woody from './Woody';
import AllChats from './AllChats';
import TeamAnalyticsDashboard from './TeamAnalyticsDashboard';
import AutoMessages from './AutoMessages'; // Import the new component

function TeamDashboard() {
  const [users, setUsers] = useState([]);
  const [activeComponent, setActiveComponent] = useState('ListSongs');

  const switchComponent = (componentName) => {
    setActiveComponent(componentName);
  };

  useEffect(() => {
    const usersRef = ref(database, 'Users');
    onValue(usersRef, (snapshot) => {
      const usersData = snapshot.val();
      const userList = usersData ? Object.keys(usersData).map(key => ({
        id: key,
        ...usersData[key]
      })) : [];
      setUsers(userList);
    });
  }, []);

  return (
    <div className="team-dashboard-container">
      <div className="team-dashboard-sidebar">
      <h2 style={{fontSize: '15px', textAlign: 'left', fontFamily: 'Wide', marginBottom: '18px'}}>Team Dashboard</h2>
        <button 
          onClick={() => switchComponent('ListSongs')} 
          className={`team-dashboard-button ${activeComponent === 'ListSongs' ? 'team-dashboard-active-button' : ''}`}>
          <img src="./img/grant.webp" alt="List Songs Icon" className="team-dashboard-icon" /> Review Songs
        </button>
        <button 
          onClick={() => switchComponent('AdminAddSong')} 
          className={`team-dashboard-button ${activeComponent === 'AdminAddSong' ? 'team-dashboard-active-button' : ''}`}>
          <img src="./img/upload-song.webp" alt="Add Song Icon" className="team-dashboard-icon" /> Add Song
        </button>
        <button 
          onClick={() => switchComponent('ListArtists')} 
          className={`team-dashboard-button ${activeComponent === 'ListArtists' ? 'team-dashboard-active-button' : ''}`}>
          <img src="./img/contacts.webp" alt="List Artists Icon" className="team-dashboard-icon" /> Registrations (Artists)
        </button>
        <button 
          onClick={() => switchComponent('ListExperts')} 
          className={`team-dashboard-button ${activeComponent === 'ListExperts' ? 'team-dashboard-active-button' : ''}`}>
          <img src="./img/expert_contacts.webp" alt="List Experts Icon" className="team-dashboard-icon" /> Registrations (Experts)
        </button>
        <button 
          onClick={() => switchComponent('SpotlightArtist')} 
          className={`team-dashboard-button ${activeComponent === 'SpotlightArtist' ? 'team-dashboard-active-button' : ''}`}>
          <img src="./img/spotlight.webp" alt="Spotlight Artist Icon" className="team-dashboard-icon" /> Spotlight Artist
        </button>
        <button 
          onClick={() => switchComponent('AwardCreator')} 
          className={`team-dashboard-button ${activeComponent === 'AwardCreator' ? 'team-dashboard-active-button' : ''}`}>
          <img src="./img/aaa_pass.webp" alt="Awards Icon" className="team-dashboard-icon" /> Awards
        </button>
  
        <button 
          onClick={() => switchComponent('ArtPages')} 
          className={`team-dashboard-button ${activeComponent === 'ArtPages' ? 'team-dashboard-active-button' : ''}`}>
          <img src="./img/dollar_2.webp" alt="Art Pages Icon" className="team-dashboard-icon" /> Art Pages
        </button>
        <button 
          onClick={() => switchComponent('Woody')} 
          className={`team-dashboard-button ${activeComponent === 'Woody' ? 'team-dashboard-active-button' : ''}`}>
          <img src="./img/plus_icon.webp" alt="Woody Icon" className="team-dashboard-icon" /> Woody Email Database
        </button>
        <button 
          onClick={() => switchComponent('AllChats')} 
          className={`team-dashboard-button ${activeComponent === 'AllChats' ? 'team-dashboard-active-button' : ''}`}>
          <img src="./img/big_chat.webp" alt="All Chats Icon" className="team-dashboard-icon" /> All Chats
        </button>
        <button 
          onClick={() => switchComponent('TeamAnalyticsDashboard')} 
          className={`team-dashboard-button ${activeComponent === 'TeamAnalyticsDashboard' ? 'team-dashboard-active-button' : ''}`}>
          <img src="./img/analytico.webp" alt="Analytics Dashboard Icon" className="team-dashboard-icon" /> Analytics Dashboard
        </button>
        <button 
          onClick={() => switchComponent('AutoMessages')} 
          className={`team-dashboard-button ${activeComponent === 'AutoMessages' ? 'team-dashboard-active-button' : ''}`}>
          <img src="./img/widget.webp" alt="Auto Messages Icon" className="team-dashboard-icon" /> Auto Messages
        </button>
      </div>

      <div className="team-dashboard-content">
        {activeComponent === 'ListSongs' && <ListSongs />}
        {activeComponent === 'AdminAddSong' && <AdminAddSong />}
        {activeComponent === 'ListArtists' && <ListArtists />}
        {activeComponent === 'SpotlightArtist' && <SpotlightArtist />}
        {activeComponent === 'AwardCreator' && <AwardCreator />}
        {activeComponent === 'ListExperts' && <ListExperts />}
        {activeComponent === 'ArtPages' && <ListPages />}
        {activeComponent === 'Woody' && <Woody />}
        {activeComponent === 'AllChats' && <AllChats />}
        {activeComponent === 'TeamAnalyticsDashboard' && <TeamAnalyticsDashboard />}
        {activeComponent === 'AutoMessages' && <AutoMessages />}
      </div>
    </div>
  );
}

export default TeamDashboard;
