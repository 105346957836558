import React, { useState, useEffect, useRef } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import CountryFlagEmoji from '../Utility/CountryFlagEmoji';
import './ArtistTop.css';
import CreateCollabModal from './CreateCollabModal';
import BuyProModal from './BuyProModal';
import CollabLoginPopup from './CollabLoginPopup';
import FinishSettingsModal from './FinishSettingsModal';
import Lottie from 'react-lottie-player';
import celebrateAnimation from '../img/celebra_red.json';
import CollabSuccessModal from './CollabSuccessModal';
import { ref as databaseRef, get } from 'firebase/database';
import { database } from '../firebaseConfig';

const ArtistTop = ({ artistDetails, artistID }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showCollabModal, setShowCollabModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showFinishSettingsModal, setShowFinishSettingsModal] = useState(false);
  const [showCollabLoginPopup, setShowCollabLoginPopup] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [userCredits, setUserCredits] = useState(0);
  const [isPremium, setIsPremium] = useState(false);
  const [hasSettings, setHasSettings] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [showLottie, setShowLottie] = useState(false); // Initial auf false setzen
  const [isChatExists, setIsChatExists] = useState(false);

  const modalRef = useRef(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsLoggedIn(true);
        setCurrentUserId(user.uid);

        const userRef = databaseRef(database, `Artists/${user.uid}`);
        const userSnapshot = await get(userRef);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.val();
          setUserCredits(userData.collabCredits || 0);
          setIsPremium(userData.isPremium || false);
          setHasSettings(userData.hasSettings || false);
        }

        // Check if a chat already exists between the current user and the artist
        checkChatExists(user.uid, artistID);
      } else {
        setIsLoggedIn(false);
        setCurrentUserId(null);
      }
    });

    return () => unsubscribe();
  }, [artistID]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLottie(true);
    }, 1000); // 0.5 Sekunden Verzögerung

    return () => clearTimeout(timer); // Cleanup bei Komponentende-Montage
  }, []);

  const checkChatExists = async (currentUserId, artistID) => {
    const chatId1 = `${currentUserId}_${artistID}`;
    const chatId2 = `${artistID}_${currentUserId}`;

    const chatRef1 = databaseRef(database, `Chats/${chatId1}`);
    const chatRef2 = databaseRef(database, `Chats/${chatId2}`);

    const chatSnapshot1 = await get(chatRef1);
    const chatSnapshot2 = await get(chatRef2);

    if (chatSnapshot1.exists() || chatSnapshot2.exists()) {
      setIsChatExists(true);
    }
  };

  const handleSendMessage = () => {
    if (isChatExists) return;

    if (!isLoggedIn) {
      setShowCollabLoginPopup(true);
      return;
    }

    if (hasSettings === false) {
      setShowFinishSettingsModal(true);
      return;
    }

    if (isPremium || userCredits > 0) {
      setShowCollabModal(true);
    } else {
      setShowUpgradeModal(true);
    }
  };

  const handleLottieComplete = () => {
    setShowLottie(false);
  };

  const handleOverlayClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setShowCollabModal(false);
      setShowUpgradeModal(false);
      setShowFinishSettingsModal(false);
      setShowCollabLoginPopup(false);
    }
  };

  const handleSuccessMessage = async () => {
    setShowSuccessModal(true);
    await checkChatExists(currentUserId, artistID); // Überprüfe den Chatstatus erneut
    setTimeout(() => {
      setShowSuccessModal(false);
    }, 3000);
  };

  return (
    <div className="artist-top" onClick={handleOverlayClick}>
      {showCollabModal && currentUserId && (
        <div ref={modalRef}>
          <CreateCollabModal
            senderId={currentUserId}
            receiverId={artistID}
            receiverImageUrl={artistDetails.artistImageUrl}
            onClose={() => setShowCollabModal(false)}
            onMessageSent={(receiverId) => {
              setShowCollabModal(false);
              handleSuccessMessage(); // Aktualisiere den Status des Buttons
            }}
          />
        </div>
      )}
      {showUpgradeModal && (
        <div ref={modalRef}>
          <BuyProModal onClose={() => setShowUpgradeModal(false)} />
        </div>
      )}
      {showFinishSettingsModal && (
        <div ref={modalRef}>
          <FinishSettingsModal onClose={() => setShowFinishSettingsModal(false)} />
        </div>
      )}
      {showCollabLoginPopup && (
        <div ref={modalRef}>
          <CollabLoginPopup onClose={() => setShowCollabLoginPopup(false)} />
        </div>
      )}
      {showSuccessModal && (
        <CollabSuccessModal onClose={() => setShowSuccessModal(false)} />
      )}
      <div className="awards-profile-banner">
        {artistDetails.numberAwards > 0 && (
          <div className="award-profile">
            <h2>{artistDetails.numberAwards}X</h2>
            <hr />
            <span className="award-label">
              <img className="award-img" src="../img/award.svg" alt="Award" />
              AAA Awards <p>Winner</p>
            </span>
          </div>
        )}
        {artistDetails.numberNominated > 0 && (
          <div className="award-profile">
            <h2>{artistDetails.numberNominated}X</h2>
            <hr />
            <span className="award-label">
              <img className="award-img" src="../img/award.svg" alt="Nominee" />
              AAA Awards <p>Nominee</p>
            </span>
          </div>
        )}
      </div>
      <img 
        src={artistDetails.artistImageUrl} 
        alt={artistDetails.artistName} 
        className="artist-image-2" 
      />
      {(artistDetails.numberAwards > 0 || artistDetails.numberNominated > 0) && showLottie && (
        <Lottie
          loop={false}
          animationData={celebrateAnimation}
          play
          onComplete={handleLottieComplete}
          style={{
            position: 'absolute',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            zIndex: 0, // Ensure it's behind the profile image
          }}
        />
      )}
      <div className="artist-info">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <h2 className="artist-name">{artistDetails.artistName}</h2>
          <CountryFlagEmoji country={artistDetails.country} />
        </div>
        <div className="artist-details-info">
          <p className="artist-genre">{artistDetails.artistType}</p>
          {!artistDetails.isExpert && (
            <>
              <hr />
              <p className="artist-genre">{artistDetails.genre}</p>
              <hr />    
            </>
          )}
          <p className="artist-genre">{artistDetails.country}</p>
        </div>
        <div className="artist-buttons">
          <button
            onClick={handleSendMessage}
            className="send-profile-message-button"
            disabled={isChatExists} // Button deaktivieren, wenn ein Chat existiert
            style={{ background: isChatExists ? '#0a0a0a' : '', cursor: isChatExists ? 'not-allowed' : 'pointer' }}
          >
            <p>{isChatExists ? 'Chat Exists' : 'Collaborate'}</p>
            <img src="../img/link-to-device.svg" alt="" />
          </button>
        </div>
        <p className="artist-bio-1">{artistDetails.artistBioText}</p>
        <div className="artist-awards">
        </div>
      </div>
    </div>
  );
};

export default ArtistTop;
