import React from 'react';
import './MyAwards.css'; 
import DashboardNav from './DashboardNav';
import LoadingScreen from '../Utility/LoadingScreen';
import DashboardMobileNav from './DashboardMobileNav';
import NoAwardsScreen from './NoAwardsScreen';

function formatDate(isoString) {
  const date = new Date(isoString);
  return new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric', year: 'numeric' }).format(date);
}

function MyAwards({ filteredAwards }) {
  const songs = filteredAwards;

  return (
    <div className="myAwards2">
      <DashboardNav />
      <DashboardMobileNav />

      {!songs ? (
        <LoadingScreen />
      ) : (
        <div className="songsGrid">
          {songs.length > 0 ? (
            songs.map((song) => (
              <div key={song.id} className="songAwardItem" style={{ '--background-url': `url(${song.songCoverUrl})` }}>
                <div className="songDetails_2">
                  <img src="./img/logo.svg" alt="" />
                  <div>
                    <h4>IN RECOGNITION OF YOUR PARTICIPATION AS</h4>
                    <h3>{song.selectedArtistType}</h3>
                    <h4>ON THE TRIPLE A AWARD-NOMINATED RECORDING</h4>
                    <h3 style={{ marginBottom: '0px' }}>{song.name}</h3>
                    <p style={{ fontSize: '12px' }} className="artistName_2">by {song.artist}</p>
                    <h2>{song.awardDate ? 'Award Winner' : 'Award Nominated'}</h2>
                    <h6>{song.awardDate ? formatDate(song.awardDate) : formatDate(song.nominatedDate)} - Industry Newcomer Picks</h6>
                  </div>
                  <div className="artist-certificate-image-container">
                    <img src={song.songCoverUrl} alt={`${song.name} cover`} />
                    <img
                      className={song.awardDate ? "awardWinnerSeal" : "nominatedSeal"}
                      src={song.awardDate ? "./img/award_winner_siegel.svg" : "./img/tripple_a_siegel.svg"}
                      alt={song.awardDate ? "Award Winner Seal" : "Nominated Seal"}
                    />
                  </div>
                  <h6 style={{ color: 'lightgray' }}>2024 TRIPLE A MUSIC AWARDS</h6>
                  <img className="certificate-signature" src="./img/signature.png" alt="" />
                </div>
              </div>
            ))
          ) : (
            <div>
              <NoAwardsScreen />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default MyAwards;
