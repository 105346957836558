import React from 'react';
import { Link } from 'react-router-dom';
import './SubmissionScreen.css'; // CSS-Datei für Styling

function SubmissionScreen({user}) {
    return (
        <div className="submission-screen">
                  <div className="pulsator"></div>
             <div className="song-stepper-header">
            <img src="./img/logo.svg" alt="" />
            <div>
            <p style={{fontSize: '14px', marginTop: '17px', marginRight: '25px', letterSpacing: '1px'}}> Credits: {user.songCredits || 0}</p>
                {user && <img src={user.artistImageUrl} alt="Artist" className="user-image-top"/>}
            </div>
        </div>
        {/* <img className="mobile-submission-logo" src="./img/logo.svg" alt="" /> */}
            <div className="container top-container">
                <p>Profile in Review</p>
            </div>
            <div className="submission-screen-bottom-wrapper">
            <div className="container bottom-container">
                <div>
                <img style={{marginBottom: '10px'}} src="./img/award.webp" alt="" />
                </div>
                <h3>Submit Song</h3>
                <h4>Submit your best track and win your first AAA Music Award.</h4>
                <Link to="/submit-song" style={{textDecoration: 'none'}} className="sub-next-button">
                   <p>Start here</p>
                   <img src="./img/right.svg" alt="" />
                </Link>
            </div>
            </div>
        </div>
    );
}

export default SubmissionScreen;
