import React, { useState, useEffect } from 'react';
import { ref as databaseRef, get, set } from 'firebase/database';
import { database, auth } from '../../firebaseConfig';
import SaveFavoritesButton from '../PageUtility/SaveFavoritesButton';
import GigDetailedPreview from '../../Pages/GigDetailedPreview';
import DashboardNav from '../DashboardNav';
import './MyFavorites.css';
import CollabSong from '../../Pages/CollabSong';
import { Link } from 'react-router-dom';

const MyFavorites = () => {
    const [artistFavorites, setArtistFavorites] = useState([]);
    const [collabFavorites, setCollabFavorites] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showCollabs, setShowCollabs] = useState(true); // Switch between Collabs and Gigs

    useEffect(() => {
        const fetchFavorites = async () => {
            const currentUser = auth.currentUser;
            if (!currentUser) {
                setIsLoading(false);
                return;
            }
    
            const userId = currentUser.uid;
    
            try {
                // Fetch artist favorites (Gigs)
                const favRef = databaseRef(database, `/Artists/${userId}/favorites`);
                const snapshot = await get(favRef);
                let artistFavoritesInfo = [];
                if (snapshot.exists()) {
                    const favoritesData = snapshot.val();
                    artistFavoritesInfo = await Promise.all(Object.keys(favoritesData).map(async key => {
                        const { artistId, pageId } = favoritesData[key];
                        const [artistSnapshot, pageSnapshot] = await Promise.all([
                            get(databaseRef(database, `/Artists/${artistId}`)),
                            get(databaseRef(database, `/Pages/${pageId}`))
                        ]);
    
                        const artistInfo = artistSnapshot.exists() ? artistSnapshot.val() : {};
                        const pageInfo = pageSnapshot.exists() ? pageSnapshot.val() : {};
    
                        let gigDetails = {};
                        if (pageInfo && pageInfo.pageData && Array.isArray(pageInfo.pageData.offers) && pageInfo.pageData.offers.length > 0) {
                            const firstOffer = pageInfo.pageData.offers[0];
                            gigDetails = {
                                gigTitle: firstOffer.gigTitle,
                                gigText: firstOffer.gigText,
                                earnAmount: firstOffer.earnAmount,
                                gigTime: firstOffer.gigTime,
                                galleryImage: Array.isArray(pageInfo.pageData.gallery) && pageInfo.pageData.gallery.length > 0 ? pageInfo.pageData.gallery[0] : null,
                                directMessageEnabled: pageInfo.pageData.directMessageEnabled || false,
                                service: pageInfo.pageData.service || 'Unspecified',
                                route: pageInfo.route || ''
                            };
                        }
    
                        // Only return if page is live
                        return pageInfo && pageInfo.pageData && pageInfo.pageData.pageIsLive ? 
                            { ...artistInfo, ...gigDetails, artistId, pageId } : 
                            null;
                    }));
                    setArtistFavorites(artistFavoritesInfo.filter(fav => fav !== null));
                }
    
                // Fetch collab favorites
                const collabFavRef = databaseRef(database, `/Artists/${userId}/collabFavorites`);
                const collabSnapshot = await get(collabFavRef);
                let collabFavoritesInfo = [];
                if (collabSnapshot.exists()) {
                    const collabFavoritesData = collabSnapshot.val();
                    collabFavoritesInfo = await Promise.all(Object.keys(collabFavoritesData).map(async key => {
                        const { artistId, pageId } = collabFavoritesData[key];
                        const [songSnapshot, artistSnapshot] = await Promise.all([
                            get(databaseRef(database, `/Songs/${pageId}`)),
                            get(databaseRef(database, `/Artists/${artistId}`))
                        ]);
                        const songInfo = songSnapshot.exists() ? songSnapshot.val() : {};
                        const artistInfo = artistSnapshot.exists() ? artistSnapshot.val() : {};
    
                        const hasUserLiked = songInfo.likes && songInfo.likes[userId] ? true : false;

                        return {
                            id: pageId, // Stellen Sie sicher, dass id und artistId korrekt zugewiesen werden.
                            artistID: artistId,
                            artistName: artistInfo.artistName || 'Unknown Artist',
                            artistImageUrl: artistInfo.artistImageUrl || '',
                            totalLikes: songInfo.likes ? Object.keys(songInfo.likes).length : 0,
                            hasUserLiked,
                            ...songInfo,
                        };
                    }));
                    setCollabFavorites(collabFavoritesInfo.filter(fav => fav !== null));
                }

                // Set default view based on the number of favorites
                if (collabFavoritesInfo.length < artistFavoritesInfo.length) {
                    setShowCollabs(true);
                } else if (artistFavoritesInfo.length < collabFavoritesInfo.length) {
                    setShowCollabs(false);
                } else {
                    setShowCollabs(false); // Default to Gigs if equal
                }

                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching favorites:", error);
                setIsLoading(false);
            }
        };
    
        fetchFavorites();
    }, []);
    
    const likeSong = async (songId) => {
        const userId = auth.currentUser.uid;
        const songRef = databaseRef(database, `Songs/${songId}/likes/${userId}`);
        const likeSnapshot = await get(songRef);

        if (likeSnapshot.exists()) {
            // Remove the like if it exists
            await set(songRef, null);

            // Update the state
            setCollabFavorites(prevCollabs =>
                prevCollabs.map(song =>
                    song.id === songId ? { ...song, hasUserLiked: false, totalLikes: Math.max(song.totalLikes - 1, 0) } : song
                )
            );
        } else {
            // Add the like if it doesn't exist
            await set(songRef, { timestamp: new Date().toISOString() });

            // Update the state
            setCollabFavorites(prevCollabs =>
                prevCollabs.map(song =>
                    song.id === songId ? { ...song, hasUserLiked: true, totalLikes: song.totalLikes + 1 } : song
                )
            );
        }
    };

    const toggleFavorite = async (songId, artistId) => {
        const userId = auth.currentUser.uid;
        if (!songId || !artistId) {
            console.error("songId or artistId is undefined", { songId, artistId });
            return;
        }
        const favoriteRef = databaseRef(database, `Artists/${userId}/collabFavorites/${songId}`);
        const favoriteSnapshot = await get(favoriteRef);

        if (favoriteSnapshot.exists()) {
            // Remove the favorite if it exists
            await set(favoriteRef, null);

            // Update the state
            setCollabFavorites(prevCollabs =>
                prevCollabs.filter(song => song.id !== songId)
            );
        } else {
            // Add the favorite if it doesn't exist
            const favoriteData = {
                artistId: artistId,
                pageId: songId,
                timestamp: new Date().toISOString(),
                type: 'collab'
            };
            await set(favoriteRef, favoriteData);

            // Update the state
            setCollabFavorites(prevCollabs => [...prevCollabs, { ...favoriteData, hasUserLiked: true }]);
        }
    };
    
    if (isLoading) {
        return <div><DashboardNav /></div>;
    }

    if (artistFavorites.length === 0 && collabFavorites.length === 0) {
        return (
            <div className="chat-list-container">
                <DashboardNav />
                <div className="no-chats-message">
                    <img style={{ width: '100px' }} src="../../img/heart_red.webp" alt="" />
                    <h2>No Favorites</h2>
                    <p>You haven't added any favorites yet.</p>
                    <Link to="/collaborations"><p>Discover Opportunities</p></Link>
                </div>
            </div>
        );
    }

    return (
        <div className="myFavorites">
            <DashboardNav />
            <h1>My Favorites</h1>
            
            <div className="favorites-switch">
                <button 
                    className={`switch-button ${showCollabs ? 'active' : ''}`} 
                    onClick={() => setShowCollabs(true)}
                >
                    Collabs
                </button>
                <button 
                    className={`switch-button ${!showCollabs ? 'active' : ''}`} 
                    onClick={() => setShowCollabs(false)}
                >
                    Gigs
                </button>
            </div>

            {showCollabs ? (
                <div className="my-favorites-grid">
                    {/* Collab Favorites */}
                    {collabFavorites.map((song, index) => (
                        <CollabSong
                            key={song.id}
                            song={song}
                            isPlaying={false}
                            currentSong={null}
                            togglePlayPause={() => {}}  // Implement play functionality if needed
                            likeSong={() => likeSong(song.id)}  // Verwende hier die likeSong-Funktion
                            saveCollabFavorite={() => toggleFavorite(song.id, song.artistID)}
                            existingChats={{}}  // Replace with actual chat data if available
                            songRef={null}
                            openCollabModal={() => {}}  // Implement if needed
                        />
                    ))}
                </div>
            ) : (
                <div className="my-favorites-grid">
                    {/* Artist Favorites (Gigs) */}
                    {artistFavorites.map((fav, index) => (
                        <div key={index} className="my-favorite-item" style={{position: 'relative'}}>
                            <SaveFavoritesButton artistId={fav.artistId} pageId={fav.pageId} className="save-button-container" />
                            <GigDetailedPreview gig={fav} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MyFavorites;
