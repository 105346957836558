import React from 'react';
import AutoMessage from './AutoMessage';
import './AutoMessages.css';

function AutoMessages() {
  return (
    <div className="auto-messages-container">
      {/* Section for ReviewTriggerInbox */}
      <div className="auto-message-section">
        <h2>ReviewTriggerInbox</h2>
        <AutoMessage path="Tasks/ReviewTriggerInbox" messageType="ReviewTriggerInbox" />
      </div>

      {/* Section for ValerieMessage */}
      <div className="auto-message-section">
        <h2>ValerieMessage</h2>
        <AutoMessage path="Tasks/ValerieMessage" messageType="ValerieMessage" />
      </div>

      {/* Section for MariusReview */}
      <div className="auto-message-section">
        <h2>MariusReview</h2>
        <AutoMessage path="Tasks/MariusReview" messageType="MariusReview" />
      </div>

      {/* Section for AbdiMessage */}
      <div className="auto-message-section">
        <h2>AbdiMessage</h2>
        <AutoMessage path="Tasks/AbdiMessage" messageType="AbdiMessage" />
      </div>
      <div className="auto-message-section">
  <h2>MoesardMail</h2>
  <AutoMessage 
    path="Tasks/MoesardMail" 
    messageType="MoesardMail" 
    specialFields={['htmlSnippet', 'subject']} // Neue Felder nur für MoesardMail
  />
</div>
<div className="auto-message-section">
  <h2>Lady Mail</h2>
  <AutoMessage 
    path="Tasks/LadyMail" 
    messageType="LadyMail" 
    specialFields={['htmlSnippet', 'subject']} // Neue Felder nur für MoesardMail
  />
</div>

    </div>
  );
}

export default AutoMessages;
