import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './DashboardMobileNav.css';

function DashboardMobileNav() {
    const location = useLocation();
    const [lastScrollY, setLastScrollY] = useState(window.scrollY);
    const [navClass, setNavClass] = useState('nav-visible');

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        if (currentScrollY > lastScrollY) {
            setNavClass('nav-hidden');
        } else {
            setNavClass('nav-visible');
        }
        setLastScrollY(currentScrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    const navItems = [
        { path: '/dashboard', label: 'My Awards', icon: 'award_dark.webp', activeIcon: 'award_red.webp' },
        { path: '/dashboard', label: 'Upload', icon: 'plus.webp', activeIcon: 'business_red.webp' },
        { path: '/chats', label: 'Chats', icon: 'bubble_dark.webp', activeIcon: 'bubble_red.webp' },
        { path: '/market', label: 'Market', icon: 'business_dark.webp', activeIcon: 'heart_red.webp' },
    ];

    return (
        <div className={`mobile-nav-container ${navClass}`}>
            {navItems.map(item => (
                <Link to={item.path} key={item.label} className={`mobile-nav-link ${location.pathname === item.path ? 'mobile-nav-link-active' : ''}`}>
                    <img 
                        src={`./img/${location.pathname === item.path ? item.activeIcon : item.icon}`} 
                        alt={item.label} 
                        className="mobile-nav-icon" 
                    />
                    <p className="mobile-nav-label">{item.label}</p>
                </Link>
            ))}
        </div>
    );
}

export default DashboardMobileNav;
